/* You can add global styles to this file, and also import other style files */
@use 'sass:list';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
.clr-input-group .clr-input-group-icon-action,
clr-datalist-container .clr-datalist-caret {
  margin-left: -1.4rem !important;
}

.clr-control-inline {
  flex-direction: row;
}

.clr-nav-close {
  --color: #cccccc;
}

/* z-index */
.main-container.open-hamburger-menu .header-nav.clr-nav-level-1 {
  padding-top: max(1%, 60px);
  z-index: 6;
}

.main-container.open-hamburger-menu .header .header-backdrop {
  z-index: 5;
}

#overlay {
  z-index: 4;
}

#wrapperprogressbars,
#progresstrigger {
  z-index: 3;
}

#wrapperdetailview,
#overlaySwitcher,
#overlaySwitcherTriggerWrapper {
  z-index: 2;
}
#position, #info, .datagrid-host {
  z-index: 1;
}

/* z-index */

clr-tabs,
.tab-content {
  display: block;
}

clr-tabs {
  height: 100%;

  .tab-content {
    height: calc(100% - 36px);
  }
}

clr-tabs.overflow-tab {
  overflow: hidden; // Erforderlich für die Berechnung von dynamicOverflow

  ul {
    column-gap: 3px;
    width: max-content;
  }
}

.btn-group.wrap-button {
  flex-wrap: wrap;
  justify-content: flex-end;

  div.btn-group-overflow,
  button.btn {
    flex: 1 1 0;
  }
}

clr-datagrid {
  max-height: 100%;
  min-height: 120px;

  .datagrid {
    min-height: 75px;
  }

  &.phSmall clr-dg-placeholder {
    max-height: 20px;
  }

  &.dg-overflow-fix clr-dg-placeholder {
    height: 2px;
  }

  clr-dg-placeholder.hidePlaceholder > .datagrid-placeholder > .datagrid-placeholder-image {
    display: none;
  }
}

clr-datagrid.wrapColumn {
  .datagrid-table {
    width: 100% !important;
    flex-basis: 100% !important;
  }

  .datagrid-row-scrollable,
  clr-dg-column,
  clr-dg-cell {
    width: calc(100% - 50px) !important;
    flex-basis: calc(100% - 50px) !important;
  }
}

clr-datagrid.no-min-width-grid {

  .datagrid-table .datagrid-column,
  .datagrid-table .datagrid-cell {
    min-width: 0;
  }
}

clr-datagrid clr-dg-row.datagrid-row:has(:is(.datagrid-row-actions,
  .datagrid-select,
    .sticky-action)) {
  overflow-y: clip;
}

clr-datagrid.sticky-action-column {

  clr-dg-column.sticky-action,
  clr-dg-cell.sticky-action {
    position: sticky !important;
    left: -5px;
    z-index: 1;
    background-color: inherit;
  }

  .datagrid :has(.sticky-action):not(:is(

    /* diese haben background-color separat gesetzt */
      .headerRow, clr-dg-row:hover, div.datagrid-row)) {
    background-color: inherit;
  }
}

.h-auto,
.h-w-auto,
.w-h-auto,
.auto-height,
[class*=auto-height-] {
  height: auto;
  max-height: none;
}

@media screen and (min-width: 576px) {

  .auto-height-sm-100,
  .auto-height-sm {
    height: 100%;
    max-height: 100%;
  }

  .auto-height-sm-60 {
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
  }
}

@media screen and (min-width: 768px) {

  .auto-height-md-100,
  .auto-height-md {
    height: 100%;
    max-height: 100%;
  }

  .auto-height-md-60 {
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
  }
}

@media screen and (min-width: 992px) {

  .auto-height-lg-100,
  .auto-height-lg {
    height: 100%;
    max-height: 100%;
  }

  .auto-height-lg-60 {
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
  }
}

@media screen and (min-width: 1200px) {

  .auto-height-xl-100,
  .auto-height-xl {
    height: 100%;
    max-height: 100%;
  }

  .auto-height-xl-60 {
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
  }
}

.clr-accordion-header-button:has(> clr-accordion-title.basealign),
clr-tree-node.basealign .clr-tree-node-content-container,
.clr-treenode-content {
  align-items: baseline;
}

clr-tree clr-tree-node .clr-treenode-children {
  overflow: hidden !important;
}

//! Falls clr-side-panel (die technisch auch clr-modal ist) hat innere clr-modal
clr-side-panel.side-panel .modal:not(:has(> .modal-dialog > .modal-content-wrapper > .modal-content > .modal-body-wrapper > .modal-body > .side-panel-body)) {
  left: 0;

  .modal-dialog {
    height: unset;
  }
}

app-preview-thumbnails {
  flex: 1 1 100%;
  max-width: 100%;
}

.dropzoneBox {
  display: flex;
  flex: 0 0 250px;
  align-self: flex-start;
  margin-top: 15px;

  &>.innerWrapper,
  &:not(:has(>.innerWrapper)) {
    border-color: currentColor;
    border-style: dashed;
    border-width: 3px;
    padding: 10px 10px;
    margin: 25px;
    text-align: center;
    align-items: center;
    justify-content: space-between;
  }

  &.drop-zone-active {

    &>.innerWrapper,
    &:not(:has(>.innerWrapper)) {
      background-color: #23929247;
    }
  }
}

form.no-margin .clr-form-control {
  margin-top: 0;
}

.width100 {

  clr-datalist-container,
  clr-select-container,
  clr-input-container,
  clr-textarea-container,
  clr-date-container {

    .clr-control-container,
    .clr-select-wrapper,
    .clr-input-wrapper,
    .clr-input-group,
    input,
    select,
    textarea {
      width: 100%;
      max-width: 100%;
    }
  }
}

clr-textarea-container.fill-space {

  .clr-control-container,
  .clr-textarea-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
  }
}

th.headerRow,
tr.headerRow,
clr-dg-row.headerRow,
clr-dg-row.headerRow:hover,
clr-dg-row.headerRow.datagrid-selected {
  background-color: #155165;
  color: #EEF1F2;
}

div.border-table {
  border-width: 1px;
  border-style: solid;
  border-color: var(--clr-table-border-color, hsl(198, 0%, 80%));

  > table {
    border-width: 0;
  }
}

.dark-theme div.border-table {
  border-color: hsl(208, 16%, 34%);
}

table .clr-form-control {
  margin-top: 0;
}

img.auto {
  object-fit: contain;
  max-height: inherit;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: auto;
}

.invalidRed {
  --color: var(--clr-forms-invalid-text-color, hsl(9, 100%, 38%));
  color: var(--color) !important;
}

.successGreen {
  --color: var(--clr-forms-valid-text-color, hsl(93, 100%, 16%));
  color: var(--color) !important;
}

.headerFont {
  color: var(--clr-header-font-color, hsl(198, 0%, 98%)) !important;
}

button.btn.btn-state[ng-reflect-loading-state="2"] {
  .spinner.spinner-inline:has(+ .spinner.spinner-inline.spinner-check) {
    display: none;
  }
}

button.btn.btn-state.success .spinner.spinner-check {
  filter: hue-rotate(-100deg);
}

/* Provide a size
  style="--btn-state-size: 36px;"
  */
button.btn.btn-state.btn-state-custom-size {
  --default: 24px;
  --height: var(--btn-state-size, var(--default));
  --width: var(--btn-state-size, var(--default));

  &:not(:is(.nxp, .np)) {
    padding: 3px !important;
  }

  min-width: 12px !important;
  min-height: 12px !important;
  width: var(--width) !important;
  height: var(--height) !important;
  overflow: visible;
  line-height: calc(var(--height) - 0.5px);

  span,
  clr-icon,
  .spinner,
  .spinner::after,
  .spinner::before {
    width: 100%;
    height: 100%;
  }

  span:has(> clr-icon) {
    padding: var(--btn-state-padding, 1px);
    align-items: center;
    display: inline-flex;
  }
}

.move-buttons {
  --btn-state-size: 60px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media (width < 768px) {
    --btn-state-size: 50px;
  }

  button.btn.btn-state {
    &.add clr-icon {
      color: var(--clr-btn-link-success-color, hsl(93, 100%, 26%));
    }

    &.delete clr-icon {
      color: var(--clr-btn-link-danger-color, hsl(9, 100%, 43%));
    }

    &:is(.add, .delete) clr-icon:hover {
      filter: brightness(0.7);
    }
  }
}

.clr_disabled .datagrid-header .datagrid-select{
  pointer-events:none;
  opacity:0.5;
}

:is(clr-dg-column, :not(.datagrid-row.datagrid-selected) clr-dg-cell).disabled {
  background-color: var(--clr-color-neutral-200);
}

.iconcolor {
  color: #000;
}

.dark-theme {
  .iconcolor {

    &[shape="minus"],
    &[shape="exclamation-triangle"] {
      color: #fff !important;
    }
  }

  img.uploadImagePreview {
    &[src="/assets/icons/pdf-file-icon.png"] {
      filter: invert(1);
    }
  }
}

a.deactivate {
  color: #666;
  pointer-events: none;
}

.caption {
  font-weight: lighter;
  font-size: 150%;
  margin: 0.5rem 0;
  display: block;
}

.detailViewImage,
secure-image,
secure-image img {
  max-width: 100%;
  width: 100%;
  transition: all .5s ease-in-out;
}

.mw-image img {
  max-width: 456px;
}

.preview {
  transition: none !important;
}


.customModal {
  .modal-dialog {
    width:auto;
  }

  .modal-header {
    display: none;
  }

  .modal-header--accessible {
    padding-right: 0.2rem;

    cds-icon {
      fill: black;
    }
  }

  .modal-body{
    margin-top: -49px;

    img {
      max-height: 70vh;
      width: auto;
      max-width: 100%;
    }
  }

  .modal-content,
  .modal-body {
    padding: 0;
  }
}

.datagrid-compact {
  .datagrid-header {

    .clr-checkbox-wrapper input[type="checkbox"]+label::before,
    .clr-checkbox-wrapper input[type="checkbox"]+label::after {
      top: 0.2em;
    }
  }

  // Spalten auswählen fix
  clr-dg-column-toggle button {
    line-height: normal !important;
    height: fit-content !important;
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important;
  }
}

body {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
}


.nowrap,
.name-cell {
  white-space: nowrap;
}

.noover,
.ellipsis,
.name-cell {
  overflow: hidden;
  text-overflow: clip;
}

.ellipsis,
.name-cell {
  text-overflow: ellipsis;
}

.text-wrap {
  text-wrap: wrap;
}

.nm {
  margin: 0px !important;
}

.nxm {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.nym {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.centred {
  margin: auto;
}

.np {
  padding: 0px !important;
}

.nxp {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nyp {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mw-100-p {
  max-width: 100%;
}

.w-100-p {
  width: 100%;
}

.min-w-max-c {
  min-width: max-content !important;
}

.min-w-min-c {
  min-width: min-content !important;
}

.min-h-max-c {
  min-height: max-content !important;
}

.min-h-min-c {
  min-height: min-content !important;
}

.mw-0,
.w-auto,
.h-w-auto,
.w-h-auto {
  min-width: 0 !important;
}

.w-auto,
.h-w-auto,
.w-h-auto {
  width: auto !important;

  &.btn-link{
    line-height: normal;
  }
}

.h-60 {
  height: calc(100% - 60px);
}

.h-100 {
  height: 100%;
}

.h-100-force {
  height: 100% !important;
}

.h-100-stop {
  :has(.h-100-parent) {
    height: 100%;
  }
}

.mh-100 {
  max-height: 100%;
}

.mh-inh {
  max-height: inherit;
}

.scroll-x,
.scroll {
  overflow-x: auto;
}

.no-scroll-x,
.no-scroll {
  overflow-x: hidden;
}

.scroll-y,
.scroll {
  overflow-y: auto;
}

.no-scroll-y,
.no-scroll {
  overflow-y: hidden;
}

.d-f {
  display: flex;
}

.d-f.col {
  flex-direction: column;
}

.d-f.row {
  flex-direction: row;
}

.d-f.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cur-color {
  color: currentColor !important;
}

// #region grid
.grid,
.subgrid {
  display: grid !important;
}

.subgrid {
  grid-template-columns: subgrid !important;
}

.subgrid.subgrid-rows-only {
  grid-template-columns: unset !important;
}

.subgrid[class|=subgrid-rows] {
  grid-template-rows: subgrid !important;
}

@for $i from 1 through 5 {
  .span-#{$i} {
    grid-column-end: span #{$i};
  }
}

.span-row {
  grid-column: 1 / -1;
}

// #endregion
.container-size {
  container-type: inline-size;
}

.dark-theme clr-dg-action-overflow clr-icon {
  fill: #adbbc4;
}

#progresstrigger {
  bottom: 75px;
  width: 40px;
  position: absolute;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
  text-align: center;

  .wrap {
    cursor: pointer;
  }
}

#progresstrigger.bottom {
  bottom: 0px;
}


#progresstrigger .wrap {
  width: 40px;
  height: 30px;
  padding-top: 2px;
  margin: auto;
  color: #fff;
  background: #202a30;
}

.dark-theme #progresstrigger .wrap {
  background-color: #6c8493;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

clr-tooltip-content.tooltip-content {
  background: #fff !important;
  color: #000 !important;

  -moz-box-shadow: 2px 2px 24px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 24px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 24px -4px rgba(0, 0, 0, 0.75);
}

.tooltip .tooltip-content.tooltip-bottom-right::before,
.tooltip.tooltip-bottom-right>.tooltip-content::before {
  border-bottom-color: #fff !important;
  border-left-color: #fff !important;
}

.tooltip .tooltip-content.tooltip-top-left::before,
.tooltip .tooltip-content.tooltip-top-right::before,
.tooltip.tooltip-top-left>.tooltip-content::before,
.tooltip.tooltip-top-right>.tooltip-content::before {
  border-left-color: #fff !important;
  border-top-color: #fff !important;
}

.dark-theme {
  clr-tooltip-content.tooltip-content {
    background: #112534 !important;
    color: #fff !important;
  }

  .tooltip .tooltip-content.tooltip-bottom-right::before,
  .tooltip.tooltip-bottom-right>.tooltip-content::before {
    border-bottom-color: #112534 !important;
    border-left-color: #112534 !important;
  }

  .tooltip .tooltip-content.tooltip-top-left::before,
  .tooltip .tooltip-content.tooltip-top-right::before,
  .tooltip.tooltip-top-left>.tooltip-content::before,
  .tooltip.tooltip-top-right>.tooltip-content::before {
    border-left-color: #112534 !important;
    border-top-color: #112534 !important;
  }

  .pagination-list .pagination-current {
    color: #fff;
  }
}

clr-main-container.main-container:has(> #overlay .overlay-inner-wrapper.overlay-fullscreen) {
  > #main > clr-header {
    position: absolute;
    top: -61px;
  }

  > #overlay {
    height: 100svh;
    top: 0;
  }
}

.overlay-inner-wrapper {
  width: 100%;
  height: 100%;
  background: #fafafa;
  overflow-y: auto;
}

.main-background {
  background: hsl(198, 0%, 98%);
  background: var(--clr-global-app-background, hsl(198, 0%, 98%));
}

.dark-theme img.invertWhenDark,
img.invert {
    filter: invert(1) contrast(73%) sepia(94%) hue-rotate(153deg);
  }

.dark-theme .overlay-inner-wrapper {
  background: #202a30 !important;
}

.dark-theme .main-background {
  background: hsl(201, 30%, 15%);
}

#overlay {
  position: absolute;
  top: 60px;
  width: 100%;
  background: transparent; //background: #FAFAFA;
  height: calc(100% - 60px);
  // transition: all 2s linear;
}

// #content-area .login-wrapper .login {
//   min-height: 100%;
// }

.mobile {
  display: none;
}

.mobile.inline {
  display: none;
}

input.combobox {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .modal-body {
    overflow-x: auto;
  }

  #progresstrigger {
    bottom: 75px;
  }

  .mobile {
    display: block;
  }

  .mobile.inline {
    display: inline-block;
  }

  .hide_mobile {
    display: none !important;
  }

  .nav .nav-item {
    margin-right: 0.3em !important;
  }

  .header-nav.clr-nav-level-1 a {
    text-align: left !important;
  }

  .dg_spacer {
    display: none !important;
  }
}

.nav .nav-item {
  margin-right: 0.5em;
}

#wrapperdetailview {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 33.33%;
  height: calc(100% - 3rem);
  background: #fafafa;
  transition:
    width 0.25s ease-in-out,
    translate 0.25s ease-in-out;
}

@media screen and (max-width: 1000px) {
  #wrapperdetailview {
    width: 100% !important;
  }
}

#wrapperdetailview.split50 {
  width: 50%;
}

#wrapperdetailview.sidenavenlarged {
  width: 100%;
}

#wrapperdetailview.hidedetailview {
  translate: 100%;
}

.dark-theme #wrapperdetailview,
.dark-theme #wrapperprogressbars {
  background: #202a30 !important;
}

#wrapperprogressbars {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background: #fafafa;
  height: 75px;
  transition: width 0.25s ease-in-out;
}

#wrapperprogressbars.short {
  width: calc(100% - 33.33%);
}

#wrapperprogressbars.shorter {
  width: calc(100% - 50%);
}

.expandProgressbarButton {
  transition: transform 0.25s ease-in-out;
}


.inline-block {
  display: inline-block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.plotly:hover .modebar-btn .icon path {
  fill: rgb(86, 86, 86) !important;
}

.plotly .modebar {
  background-color: transparent !important;
}

.js-plotly-plot .plotly .main-svg g.infolayer g.legend g.scrollbox g.groups g.traces text.legendtext {
  fill: #000 !important;
}

.dark-theme .js-plotly-plot .plotly .main-svg g.infolayer g.legend g.scrollbox g.groups g.traces text.legendtext {
  fill: #adbbc4 !important;
}

.js-plotly-plot .plotly .main-svg .cartesianlayer .subplot g.xtick text {
  fill: #adbbc4 !important;
}

.js-plotly-plot .plotly .main-svg .cartesianlayer .subplot g.ytick text {
  fill: #adbbc4 !important;
}

.js-plotly-plot .plotly .main-svg .cartesianlayer .subplot g.zerolinelayer path {
  stroke: #fff !important;
}

.datagrid-compact .datagrid-cell clr-icon.customIcon {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transform: none;
  transform: none;
}

.leaflet-control-layers-group-name {
  font-weight: bold;
  margin-bottom: 0.2em;
  display: block;
}

.leaflet-control-layers-group {
  margin-bottom: 0.5em;
}

.leaflet-control-layers-group label {
  padding-left: 0.5em;
}

.leaflet-control-layers-selector {
  margin-right: 4px;
}

.leaflet-control {
  margin-top: 15px;
}

.leaflet-control-layers-scrollbar:hover {
  overflow-y: scroll;
}

.toast-container .ngx-toastr {
  box-shadow: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-left: .4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}

// Helper classes

$no: 0px;
$xs: 5px;
$sm: 10px;
$md: 15px;
$lg: 20px;
$xl: 25px;
$xs-: -1 * $xs;
$sm-: -1 * $sm;
$md-: -1 * $md;
$lg-: -1 * $lg;
$xl-: -1 * $xl;

$listVariable: $no $xs $sm $md $lg $xl $xs- $sm- $md- $lg- $xl-;
$listName: no xs sm md lg xl xs- sm- md- lg- xl-;
$listProperty: margin padding;


@each $property in $listProperty {
  @each $item in $listName {
    .#{$property}-#{$item} {
      $thisItem: list.index($listName, $item);
      $varItem: list.nth($listVariable, $thisItem);
      #{$property}: $varItem;

      & {
        #{$property}: $varItem;
      }

      &-t {
        #{$property}-top: $varItem;
      }

      &-r {
        #{$property}-right: $varItem;
      }

      &-b {
        #{$property}-bottom: $varItem;
      }

      &-l {
        #{$property}-left: $varItem;
      }

      &-tb,
      &-bt {
        #{$property}-top: $varItem;
        #{$property}-bottom: $varItem;
      }

      &-lr,
      &-rl {
        #{$property}-left: $varItem;
        #{$property}-right: $varItem;
      }
    }
  }
}


@each $prop in $listProperty {
  @for $i from -100 through 100 {
    .#{$prop} {
      &-#{$i} {
        #{$prop}: #{$i + "px"};
      }

      &-#{$i}-t {
        #{$prop}-top: #{$i + "px"};
      }

      &-#{$i}-r {
        #{$prop}-right: #{$i + "px"};
      }

      &-#{$i}-b {
        #{$prop}-bottom: #{$i + "px"};
      }

      &-#{$i}-l {
        #{$prop}-left: #{$i + "px"};
      }

      &-#{$i}-tb,
      &-#{$i}-bt {
        #{$prop}-top: #{$i + "px"};
        #{$prop}-bottom: #{$i + "px"};
      }

      &-#{$i}-lr,
      &-#{$i}-rl {
        #{$prop}-left: #{$i + "px"};
        #{$prop}-right: #{$i + "px"};
      }
    }
  }
}

@for $i from 0 through 100 {
  $prop: max-width;

  .#{mw} {
    &-#{$i}p {
      #{$prop}: #{$i + "%"};
    }
  }
}

@for $i from 0 through 10 {
  .flex {
    &-#{$i} {
      @for $j from 0 through 10 {
        &-#{$j}-0 {
          flex: #{$i} #{$j} 0;
          min-width: 0;
        }

        &-#{$j}-auto {
          flex: #{$i} #{$j} auto;
          min-width: auto;
        }
      }
    }
  }
}

.datagrid-filter .datagrid-filter-close-wrapper {
  display: none;
}